import React, { useEffect, useState } from "react";
import styles from "./ContentManagerBlock.module.scss";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";

import { FilterVerticalProps } from "./FilterBlock/FilterVertical/FilterVertical";

import FilterBlock, { FilterBlockProps } from "./FilterBlock/FilterBlock";
import { CardList, PaginationBlock, Tabs } from "SiteComponents";
import { TabsProps } from "components/utils/Tabs/Tabs";
import { CardListProps } from "components/shared/CardList/CardList";
import { SearchHitInfo } from "../SearchBlock/SearchInput/SearchInput";
import { Filter } from "./FilterBlock/FilterVertical/FilterList";
import { PaginationBlockProps } from "../PaginationBlock/PaginationBlock";
import useFetchHelper, { GenericParams } from "src/utils/useFetchHelper";
import emitter from "src/utils/emitter";
import EmptyState from "./EmptyState/EmptyState";

interface Response {
  panelData: CardListProps;
  totalCount?: number;
  query?: string;
  filterGroupList?: FilterVerticalProps[];
  labels?: {
    closeFilter?: string;
  };
  pagination: PaginationBlockProps;
}

interface Params {
  q?: string;
  tab?: string;
  sort?: string;
  page?: string;
  resultsType?: string;
  siteType?: string;
  itemsPerPage?: string;
}
export interface ContentManagerBlockProps extends ReactComponentPropsBase {
  filterBlock: FilterBlockProps;
  filterGroupList: FilterVerticalProps[];
  tabs: TabsProps;
  results?: CardListProps;
  endpoint: string;
  searchHitInfo?: SearchHitInfo;
  totalCount?: number;
  resultsType?: string;
  siteType?: string;
  itemsPerPage?: string;
  pagination?: PaginationBlockProps;
  query?: string;
  noHitsHeading: string;
  noHitsText: string;
}

const ContentManagerBlock: React.FC<ContentManagerBlockProps> = ({
  filterBlock,
  filterGroupList,
  tabs,
  results,
  endpoint,
  searchHitInfo,
  totalCount,
  resultsType,
  siteType,
  itemsPerPage,
  pagination,
  query,
  noHitsHeading,
  noHitsText,
}) => {
  const [filterState, setFilterState] =
    useState<FilterVerticalProps[]>(filterGroupList);
  const [tabPanelData, setTabPanelData] = useState<CardListProps | undefined>(
    results
  );
  const [totalCountState, setTotalCountState] = useState<number | undefined>(
    totalCount
  );
  const [queryParams, setQueryParams] = useState<Params>({
    tab: tabs.defaultActiveTab || tabs.tabList[0]?.id || "",
    resultsType: resultsType,
    siteType: siteType,
    itemsPerPage: itemsPerPage,
    q: query,
  });
  const [filterBlockLabels, setFilterBlockLabels] = useState(
    filterBlock.labels
  );
  const [paginationState, setPaginationState] = useState(pagination || {});

  const [didMount, setDidMount] = useState(false);

  const { isLoading, fetchData } = useFetchHelper();

  const fetchResults = async () => {
    try {
      const data: Response = await fetchData(
        endpoint,
        queryParams as GenericParams
      );
      setTabPanelData(data.panelData);
      setTotalCountState(data.totalCount);
      setFilterBlockLabels({ ...filterBlockLabels, ...data.labels });

      if (data.filterGroupList) {
        setFilterState(data.filterGroupList);
      }
      if (data.pagination) {
        setPaginationState({ ...paginationState, ...data.pagination });
      }
    } catch (error) {
      console.log("Error fetching results:", error);
    }
  };

  const handleTabChange = (id: string) => {
    setQueryParams({ ...queryParams, tab: id, page: "1" });
  };

  const handleFilterChange = (filterState: FilterVerticalProps[]) => {
    setFilterState(filterState);

    const filterWithArrays: { [key: string]: string[] } = filterState.reduce(
      (acc, filterGroup) => {
        (function extractFilters(filters: Filter[]) {
          filters.forEach(filter => {
            if (filter.checked) {
              if (!acc[filterGroup.id]) acc[filterGroup.id] = [];
              acc[filterGroup.id].push(filter.id);
            }
            if (filter.nestedFilters) extractFilters(filter.nestedFilters);
          });
        })(filterGroup.filters);

        return acc;
      },
      {} as { [key: string]: string[] }
    );

    const filterQueryParameters: { [key: string]: string } = Object.entries(
      filterWithArrays
    ).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value.join(","),
      };
    }, {});

    const updatedQueryParams: Record<string, string | undefined> = {
      ...queryParams,
    };

    // Remove filter groups from queryParams where there are no selected filters
    filterState.forEach(filterGroup => {
      if (!filterQueryParameters[filterGroup.id]) {
        // If no filters are selected for a group, remove it from the queryParams
        delete updatedQueryParams[filterGroup.id];
      }
    });
    setQueryParams({
      ...updatedQueryParams,
      ...filterQueryParameters,
      page: "1",
    });
  };

  const handlePaginationChange = (page: number) => {
    setPaginationState({ ...paginationState, currentPage: page });
    setQueryParams({ ...queryParams, page: page.toString() });
  };

  const handleSearchEvent = (query: string) => {
    setQueryParams({ ...queryParams, q: query, page: "1" });
  };

  const handleSortChange = (sort: string) => {
    setQueryParams({ ...queryParams, sort });
  };

  useEffect(() => {
    emitter.on("search", handleSearchEvent);

    return () => emitter.off("search", handleSearchEvent);
  }, []);

  useEffect(() => {
    if (!didMount) {
      !tabPanelData && fetchResults();
      setDidMount(true);
    } else {
      fetchResults();
    }
  }, [queryParams]);

  return (
    <div className={styles.contentManagerBlock}>
      <div className={styles.leftColumn}>
        <div className={styles.searchHitInfo}>
          {searchHitInfo && totalCount && (
            <>
              {searchHitInfo.firstPart}&nbsp;
              {totalCountState} &nbsp;
              {searchHitInfo.lastPart}
            </>
          )}
        </div>

        {filterBlock && (
          <FilterBlock
            {...filterBlock}
            labels={filterBlockLabels}
            filterState={filterState}
            onFilterChange={handleFilterChange}
          />
        )}
      </div>
      <div className={styles.rightColumn}>
        {tabs && (
          <Tabs
            {...tabs}
            onTabChange={handleTabChange}
            onSortChange={handleSortChange}
            PanelComponent={
              tabPanelData?.listItems && tabPanelData.listItems.length > 0
                ? CardList
                : EmptyState
            }
            panelComponentProps={
              tabPanelData?.listItems && tabPanelData.listItems.length > 0
                ? tabPanelData
                : {noHitsHeading, noHitsText }
            }
            isLoading={isLoading}
          />
        )}
        {paginationState && (
          <PaginationBlock
            onChange={handlePaginationChange}
            {...paginationState}
          />
        )}
      </div>
    </div>
  );
};

export default ContentManagerBlock;
