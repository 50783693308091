import StatisticsBlock, {
  StatisticsBlockProps,
} from "components/blocks/StatisticsBlock/StatisticsBlock";
import styles from "./StatisticsPage.module.scss";
import AccordionBlock from "components/blocks/AccordionBlock/AccordionBlock";
import { useEffect, useState } from "react";
import Tag from "components/base/Tag/Tag";
import useFetchHelper, { GenericParams } from "src/utils/useFetchHelper";

interface StatisticsPageProps {
  title: string;
  ingress: string;
  themeFilterList: { title: string; id: string }[];
  theme: ThemeProps;
  subThemeList: ThemeProps[];
}

interface Reponse {
  theme: ThemeProps;
  subThemeList: ThemeProps[];
}

interface ThemeProps {
  title: string;
  ingress: string;
  statisticsBlockList: StatisticsBlockProps[];
}

interface Params {
  regionCode?: string;
  themeId?: string;
}

const StatisticsPage = ({
  title,
  ingress,
  themeFilterList,
  theme,
  subThemeList,
}: StatisticsPageProps) => {
  // TODO update url with history.pushstate and history.replacestate
  // TODO Implement search field with api calls to SIM
  const [selectedTheme, setSelectedTheme] = useState();
  const [queryParams, setQueryParams] = useState<Params>({
    regionCode: "F00",
    themeId: "5304",
  });
  const [themeData, setThemeData] = useState(theme);
  const [subThemeData, setSubThemeData] = useState(subThemeList);

  const handleClick = (id: string) => {
    setQueryParams({ ...queryParams, themeId: id });
  };

  const { isLoading, fetchData } = useFetchHelper();

  const fetchResults = async () => {
    try {
      const data: Reponse = await fetchData(
        "GetStatistics",
        queryParams as GenericParams
      );
      setThemeData(data.theme);
      setSubThemeData(data.subThemeList);
    } catch (error) {
      console.error("Error fetching statistics", error);
    }
  };

  useEffect(() => {
    fetchResults();
  }, [queryParams]);

  return (
    <div>
      {title && <h1>{title}</h1>}
      {ingress && <p>{ingress}</p>}
      {themeFilterList &&
        themeFilterList.map(theme => (
          <Tag text={theme.title} onClick={() => handleClick(theme.id)} />
        ))}
      {themeData && (
        <div>
          <p>{themeData.title}</p>
          <p>{themeData.ingress}</p>
          {themeData.statisticsBlockList.length > 0 && 
            themeData.statisticsBlockList.map(block => (
              <StatisticsBlock {...block} />
            ))}
        </div>
      )}
      {subThemeData &&
        subThemeData.map(theme => (
          <div>
            <p>{theme.title}</p>
            <p>{theme.ingress}</p>
            {theme.statisticsBlockList.length > 0 &&
              theme.statisticsBlockList.map(block => (
                <StatisticsBlock {...block} />
              ))}
          </div>
        ))}
    </div>
  );
};

export default StatisticsPage;
